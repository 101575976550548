import React, { Component } from 'react';
import Ingredients from './PrintIngredients';
import Html from 'react-html-parser'
import {Link} from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { connect } from "react-redux";
import { storeUser, addNotification } from "../../redux/actions/";
const mapStateToProps = (state, props) => {
	return { 
		user: state.user, 
		uid: state.uid,
		parts: props.parts, 
		recipeName: props.recipeName, 
		pageUrl: props.pageUrl, 
		
	};
};
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        addNotification: notification => dispatch(addNotification(notification)),
	};
}
export class IngredientParts extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	parts: this.props.parts,
	  	selectedItems: [],
	  	allChecked: '',
	  };
	}
	componentDidMount(){
		this.setState({
			parts: this.props.parts,
		})
		this.handleAllButtons()
		

	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props) {
			this.handleAllButtons();
		}
	}
	
	handleSaveIngredients(){
		
		firebase.database().ref(`users/${this.props.uid}`).update({
			Shopping_List: this.state.selectedItems
		})
	}
	handleAll(e){
		// get the action value from the button so we know if we are remoing or adding all
		let buttons = document.getElementsByName('ingredient-item');
		let action = e.target.getAttribute('data-select');
		
		if(buttons){
			for(let li = 0; buttons.length > li; li++){
				setTimeout(()=>{
				buttons[li].setAttribute('data-selected', action);
				let measurement = buttons[li].getAttribute('data-measurement');
				let value = buttons[li].getAttribute('data-value');
				let category = buttons[li].getAttribute('data-category');
				let ingredient = buttons[li].getAttribute('data-ingredient');
				
				if(value === '&frac12;'){ value = 1/2 }
				if(value === '&frac13;'){ value = 1/3 }
				if(value === '&frac14;'){ value = 1/4 }
				if(value === '&frac15;'){ value = 1/5 }
				if(value === '&frac16;'){ value = 1/6 }
				if(value === '&frac17;'){ value = 1/7 }
				if(value === '&frac18;'){ value = 1/8 }
				if(value > 0){
				}
				else {
					value = 1;
				}
				if(this.props.measurement){
					measurement = this.props.measurement;
				}
				
				let recipeDetails = {
					name: this.props.recipeName.title,
					url: this.props.pageUrl,
					measurement: measurement,
					cateory: category,
					value: Html(value),
				}	
				let recipes = [recipeDetails]
				this.handleAddToShoppingList(buttons[li], measurement, Html(value), ingredient, category, recipes)
				},100)
			}	
			this.handleAllButtons()
		}
	}
	handleAddToShoppingList(e, measurement, value, ingredient, category, recipes) {
		let parsedValue = Html(value);
		let parsedInt = parseInt(parsedValue);
		if ((parsedInt * 0) !== 0) {
			value = 1;
		}
		let target = e;
		if (e.target) {
			target = e.target;
		}
		let ingredientItem = {
			measurement,
			value: parseInt(value),
			ingredient,
			category,
			recipes: recipes,
			ticked: false
		}
		let selected = target.getAttribute('data-selected');
		let items = this.state.selectedItems;
		let didMatch = false;
		let dataKey = target.getAttribute('data-key');
		if (selected === "false") {
			if (items.length > 0) {
				for (let i = 0; items.length > i; i++) {
					if (items[i].ingredient === ingredient) {
						items[i].value = parseInt(items[i].value) + parseInt(value)
						this.setState(prevState => ({
							selectedItems: items
						}))
						didMatch = true;
					}
				}
				if (didMatch === false) {
					this.setState(prevState => ({
						selectedItems: [...prevState.selectedItems, ingredientItem]
					}))
				}
			} else {
				this.setState(prevState => ({
					selectedItems: [...prevState.selectedItems, ingredientItem]
				}))
			}
			setTimeout(() => {
				this.saveIngredientsToShoppingList(this.props.uid, this.state.selectedItems)
			}, 10);
			target.setAttribute('data-selected', 'true')
			this.getUserShoppingList(this.props.uid);
		}
	
		// if unticking the select box
		else {
			for (let i = 0; items.length > i; i++) {
				if (items[i].ingredient === ingredient) {
					items[i].value = parseInt(items[i].value) - value
					if (items[i].value > 0) {
						if (items[i].recipes) {
							for (let r = 0; recipes.length > i; i++) {
								if (recipes[r] === this.props.recipeName) {
									items[i].recipes.splice(r, 1)
								}
							}
						}
					} else {
						items.splice(dataKey, 1);
						this.setState({
							selectedItems: items
						})
					}
					didMatch = true;
				}
			}
			if (didMatch === false) {
				items.splice(dataKey, 1)
				this.setState({
					selectedItems: items
				})
			}
			if (this.state.selectedItems.length > 0) {
				this.saveIngredientsToShoppingList(this.props.uid, this.state.selectedItems)
			} else {
				firebase.database().ref(`users/${this.props.uid}/Shopping_List`).remove();
			}
	
			target.setAttribute('data-selected', 'false')
			this.getUserShoppingList(this.props.uid);
		}
		this.handleAllButtons()
	}
	getUserShoppingList(uid){
		firebase.database().ref(`users/${uid}/Shopping_List`)
		.once(`value`)
		.then(snapshot => snapshot.val())
		.then((shoppingList)=>{
			return shoppingList
		});
	}
	saveIngredientsToShoppingList(uid, list){
		firebase.database().ref(`users/${uid}`).update({
			Shopping_List: list
		})
	}
	handleAllButtons(){
		let buttons = document.getElementsByName('ingredient-item');
		if(buttons){
			let nonselects = 0;
			
			for(let li = 0; buttons.length > li; li++){
				let selected = buttons[li].getAttribute('data-selected');
				if(selected !== 'true'){
					nonselects ++;
				}
			}
			if(nonselects > 0){
				this.setState({allChecked: false,})
			}	
			else {
				this.setState({allChecked: true})
			}
		}
		
	}
	render() {
		let allChecked = this.state.allChecked === true;
		let {uid, parts} = this.props;
		return (
			<>
			<div className="ingredientsWrapper">
				<h3 className="text-2xl text-secondary font-medium">Ingredients:</h3>
				{uid&&<p className="text-xs">Tick the ingredient items for these to be added to your <Link to="/profile/shopping-list">shopping list</Link></p>}
					<>
						{parts.map((item, key) =>
							<Ingredients key={key}
								heading={item.ingredient_part_heading}
								ingredients={item}
								uid={this.props.uid}
								handleAdd={this.handleAddToShoppingList.bind(this)}
								list={this.state.selectedItems}
								recipeName={this.props.recipeName}
								pageUrl={this.props.pageUrl}
								handleAll={this.handleAll.bind(this)}
								user={this.props.user}
							/>
						)}
					</>
			</div>
			{uid&&
				<div>
					{allChecked?
					<button className="rounded-lg py-2 px-4 my-2 bg-secondary text-white text-sm" onClick={this.handleAll.bind(this)} data-select='true'>
						Remove All
					</button>
					:
					<button className="rounded-lg py-2 px-4 my-2 bg-primary text-white text-sm" onClick={this.handleAll.bind(this)} data-select='false'>
						Add All
					</button>
					}
				</div>
			}
			</>
		);
	}		
}
const ConnectedIngredients = connect(mapStateToProps,mapDispatchToProps)(IngredientParts);
export default ConnectedIngredients