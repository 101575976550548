import React, { Component } from 'react';
import HtmlParser from 'react-html-parser';
import '../../Content/css/Slider.css'
import Slider from "react-slick";
import {Link} from 'gatsby';
export default class RecipeFooter extends Component {
	constructor(props){
		super(props);
		this.state = {
			size: 'small',
			slideCount: 2,
			slidesToScroll: 2,
		}
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
		if(window.innerWidth <= 767){
		  	this.setState({
		  		size: 'small',
		  		showDots: true,
		  		slideCount: 2,
				slidesToScroll: 2,
		  	})
		}
		if(window.innerWidth <= 400){
		  	this.setState({
		  		size: 'small',
		  		showDots: true,
		  		slideCount: 1,
				slidesToScroll: 1,
				speed: 400,
		  	})
		}
		if(window.innerWidth > 768){
		  	this.setState({
		  		size: 'large',
		  		showDots: true,
		  		slideCount: 4,
				slidesToScroll: 4,
				speed: 400,
		  	})
		}

	}
	render() {
		const slides = this.props.footerContent.map((item, key)=>
			<div className="recipeFooterTile">
				<Link to={item.link}>
					<img src={this.props.url + item.thumbnail} alt={item.title}/>
					<h4>{HtmlParser(item.title)}</h4>
				</Link>
			</div>
		);		
		return (
			<section className="recipeFooter">
				<h3 className="text-center text-2xl text-secondary font-medium">More Recipes</h3>
				<Slider dots={this.state.showDots}
					infinite={true}
					speed={900}
					variableWidth={false}
					useTransform={false}
					arrows={true}
					autoplaySpeed={8000}
					autoplay={true}
					fade={false}
					slidesToShow={this.state.slideCount}
      				slidesToScroll={this.state.slidesToScroll}
					pauseOnHover={true}
				>	
					{slides}
				</Slider>
			</section>
		);
	}
}
