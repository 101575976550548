import React, { Component } from 'react';
import { connect } from "react-redux";
import firebase from 'gatsby-plugin-firebase';
import { storeUser, addNotification } from "../../redux/actions/";
import ReactGA from 'react-ga';
import {Event} from '../Analytics';
ReactGA.initialize('UA-103571714-3');
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        addNotification: notification => dispatch(addNotification(notification)),
	};
}
const mapStateToProps = (state, props) => {
    return {user: state.user, uid: state.uid, recipes: state.recipes, route: props.route};
};
export class FavoriteRecipeButton extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		  favorite: false,
		  index: this.props.recipes.pages.findIndex(x => x.id === this.props.route)
	  };
	}
	reloadUser(){
		firebase.database().ref(`users/${this.props.uid}/Favorite_Recipes`)
		.once(`value`)
		.then(snapshot => snapshot.val())
		.then((favorites)=>{	
		  let user = {...this.props.user}
		  user.Favorite_Recipes = favorites
		  this.props.storeUser(user);
		})
		.catch((error)=>{console.log(error)});
	}
	componentDidMount() {
		this.checkFavorite();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.checkFavorite();
		}
	}
	checkFavorite(){
		const {Favorite_Recipes} = this.props.user;
		if(Favorite_Recipes){
			const favorites = Object.keys(Favorite_Recipes).map((key) => {
				return Favorite_Recipes[key].urlId;
			});
			const favorite = favorites.filter((item) =>{
				return  item.indexOf(this.props.route) !== -1;	
			})
			this.setState({
				favorite: favorite.length > 0,
			}) 
		} 
	}
	handleAddFavoriteRecipe(e){
		let attribute = e.target.getAttribute('data-favorite');
		const {title, uid, route} = this.props;
		if(attribute === 'true'){
			this.setState({favorite: false});
			firebase.database().ref(`users/${uid}/Favorite_Recipes/${title}`).remove()
			.then(()=>{
				this.reloadUser();
			})
			.catch((error)=>{console.log(error)})
		}
		else {
			this.setState({favorite: true});
			firebase.database().ref(`users/${uid}/Favorite_Recipes/${title}`)
			.set({
				isFavorite: true, 
				urlId: route,
			})
			.then(()=>{
				this.reloadUser();
				Event("Recipes", "Favorited Recipe", title)
				this.props.addNotification(`${title} has been added to your favorites`)
			})
			.catch((error)=>{
				console.log(error)
			})
			
		}
	}
	render() {
		
		return (
			<div className="favoriteRecipeButtonWrapper">
				{this.props.uid&&
				<button className={"button favoriteButton " + this.state.favorite}
					onClick={this.handleAddFavoriteRecipe.bind(this)}
					data-favorite={this.state.favorite}
				>
					<span className={this.state.favorite + " heartIcon"} data-favorite={this.state.favorite}>
					</span>
				</button>
				}
			</div>
		);
	}
}
const FavoriteButton = connect(mapStateToProps,mapDispatchToProps)(FavoriteRecipeButton);
export default FavoriteButton

