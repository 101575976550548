import React from 'react';

export default class NutrientValues extends React.Component {
	render() {
			let hasValues = this.props.carbs > 0 && this.props.fat > 0 && this.props.protein > 0;
			return (
				<table className="table w-full border border-gray-100 rounded-lg text-sm">
					{hasValues&&
					<tbody>
						<tr>
							<th className="py-2 px-2 text-left">Macro</th>
							<th className="py-2 px-2 text-left">Value</th>
						</tr>
						<tr className="bg-gray-100">
							<td className="py-2 px-2">Carbs:</td>
							<td className="py-2 px-2">{this.props.carbs} g</td>
						</tr>
						<tr>
							<td className="py-2 px-2">Fat:</td>
							<td className="py-2 px-2">{this.props.fat} g</td>
						</tr>
						<tr className="bg-gray-100">
							<td className="py-2 px-2">Protein:</td>
							<td className="py-2 px-2">{this.props.protein} g</td>
						</tr>
					</tbody>
					}
				</table>
			);	
	}	
}
