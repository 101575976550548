import React from 'react';
import Recipe from './Recipe'
import { connect } from "react-redux";
import { storeRecipes, addNotification } from "../../redux/actions/";
import Helmet from 'react-helmet';
let siteURL = process.env.REACT_APP_APP_URL;
let gravURL = process.env.REACT_APP_GRAV_URL;
const mapStateToProps = (state, props) => {
	return { 
		user: state.user, 
		uid: state.uid,
		parts: props.parts, 
		recipeName: props.recipeName, 
		pageUrl: props.pageUrl, 
		pageContext: props.pageContext,
		recipes: props.pageContext.allRecipes,
		
	};
};
function mapDispatchToProps(dispatch) {
	return {
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        addNotification: notification => dispatch(addNotification(notification)),
	};
}
export function singleRecipePage(props) {
	const {title,keyWords,shareImg,url} = props.pageContext.head;
	return (
		<main>
			<Helmet>
				<title>{title} | New Image Keto</title>	
				<meta property="og:image" content={gravURL + shareImg}/>
				<meta property="og:type" content="recipe"/>
				<meta property="og:url" content={url?siteURL + url:siteURL} />
				<meta property="og:image:url" content={gravURL + shareImg}/>
				<meta name="description" content={title}/>
				{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
				<meta property="og:image:width" content="600" />
				<meta property="og:image:height" content="600" />
				<meta property="og:title" content={title}/>
				<meta name="twitter:title" content={title}/>
				<meta property="og:description" content={title}/>
				<meta name="twitter:description" content={title}/>
				<meta name="twitter:image" content={gravURL + shareImg}/>
				<meta name="twitter:card" content={gravURL + shareImg}/>
			</Helmet>
			
			<Recipe 
				recipe={props.pageContext.header} 
				user={props.user}
				method={props.pageContext.content}
				urlId={props.pageContext.head.url}
				recipeFooter={props.pageContext.sidebar}
				recipeImage={props.pageContext.picture}
				recipeImageSmall={props.pageContext.picture}
				croppedImage={props.pageContext.croppedImg}
				id={props.pageContext.id}
				headContent={props.pageContext.head}
				uid={props.uid}
				recipes={props.recipes}
				storeRecipes={props.storeRecipes}
			/>
		</main>
	)
}
const SingleRecipe = connect(mapStateToProps,mapDispatchToProps)(singleRecipePage);
export default SingleRecipe