import React, {Fragment} from 'react';
import HtmlParser from 'react-html-parser';
import IngredientParts from './IngredientParts'
import PrintIngredientParts from './PrintIngredientParts'
import NutrientValues from './NutrientValues';
import VegeterianIcon from './VegeterianIcon';
import '../../Content/css/recipes.css';
import FavoriteRecipeButton from './FavoriteRecipeButton';
import RecipeFooter from './RecipeFooter';
import SocialShareButtons from './SocialShareButtons';
import ReactToPrint from 'react-to-print';
import { navigate, Link } from 'gatsby';
import mealPlansBanner from '../../images/mealPlansBanner.jpg'
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class Recipe extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			recipe: {},
			auth: this.props.userAuth,
			method: this.props.method,
			headContent: {},
			size: 'small',
			favorite: false,
			render: false,
		}
	}
	handleLockDown() {
		let title = this.props.recipe.title;
		let verified = this.props.user.verified;
		let premium = this.props.recipe.premiumRecipe;
		if (title) {
			if ((verified === 'false' || verified === false) && premium === true) {
				navigate('/recipes');
			}
			else {
				this.setState({render: true})
			}
		}
		
	}
	componentDidMount() {
		this.handleLockDown();
	}
	
	render() {
		let {recipe, recipeImage, recipeImageSmall, headContent, recipeFooter, user} = this.props;
		let small = this.state.size === 'small';
		
		return (
			<Fragment>
				{this.state.render === true &&
				<Fragment>
				<div className="pageGrid clearfix singleRecipeWrapper">
					<div className="sm:block md:flex md:flex-wrap w-full">
						{recipeImage && recipeImageSmall &&
							<div className="md:w-5/12 lg:w-6/12 recipeImgWrapper px-2">
								{small?
									<img src={gravURL+recipeImageSmall} alt={recipe.title} width="600" heght="600"/>
									:
									<img src={gravURL+recipeImage} alt={recipe.title} width="600" heght="600"/>
								}
							</div>
						}
						<div className="md:w-7/12 lg:w-6/12 md:pl-10 px-2">
							<h1 className="recipeTitle leading-tight">{HtmlParser(recipe.title)}</h1>
							
							{user?
							
								<div className="my-2 flex flex-wrap">
									{this.props.uid&& 
										<span className="py-2 mr-4">Favorite</span>
									}
									<FavoriteRecipeButton 
										uid={this.props.uid}
										id={this.props.id}
										title={HtmlParser(recipe.title)}
										route={this.props.urlId}
									/>
									<div className={`${this.props.uid && "ml-8"} lg:inline-block hidden`}>
										<ReactToPrint trigger={() => 
											<button className="text-white py-2 px-4 inline-block rounded-lg bg-secondary"><span className="flaticon-printer"></span> Print Recipe</button>}
											content={() => this.componentRef}
										/> 
									</div>
									<div className="w-full max-w-md mt-4 lg:pr-8">
										<Link to="/programme/meal-plans">
											<img src={mealPlansBanner} className="shadow-sm border rounded" alt="meal plans"/>
										</Link>
									</div>
								</div>
							:
							<div className="my-4 w-full">
								<Link to="/free-trial" className="rounded-lg bg-primary text-white py-2 px-4">
									Start Your FREE 14 Day Trial
								</Link>
								<ReactToPrint trigger={() => 
									<button className="ml-4 lg:inline-block hidden text-white py-2 px-4 inline-block rounded-lg bg-secondary">
										<span className="flaticon-printer"></span> 
										Print Recipe
									</button>}
									content={() => this.componentRef}
								/> 
								<div className="w-full max-w-md mt-4 lg:pr-8">
									<Link to="/programme/meal-plans">
										<img src={mealPlansBanner} className="shadow-sm border rounded" alt="New_Image™ Keto meal plans "/>
									</Link>
								</div>
							</div>
							}
							
							<div className="servingWrapper text-sm">
								<strong className="font-medium text-secondary">Serves:</strong> {recipe.serves}
							</div>
							<div className="vegeterianIconWrapper">
								<VegeterianIcon vegeterian={recipe.vegeterian}/>
							</div>
							<NutrientValues fat={recipe.totalFat} protein={recipe.totalProtein} carbs={recipe.totalCarbs}/>
						</div>
						<div className="md:w-11/12 md:pl-10 lg:w-6/12 px-2">
							
							<IngredientParts 
								parts={recipe.ingredients_parts}
								uid={this.props.uid}
								recipeName={recipe}
								pageUrl={this.props.urlId}
							/>
							
						</div>
						<div className="md:w-11/12 lg:w-6/12 md:pl-10 px-2">	
							<div className="methodWrapper">
								<h4 className="text-2xl text-secondary font-medium">Cooking Instructions</h4>
								<div className="preRecipe">{HtmlParser(this.props.method)}</div>
							</div>
						</div>
					</div>
					
					<div className="clearfix">
						<div className="bg-gray-100 py-2 mt-10">
							<div className="my-4 mx-auto text-center max-w-md">
								{headContent &&
									<SocialShareButtons title={this.props.headContent.facebookTitle}
										description={this.props.headContent.facebookDesc}
										image={gravURL + this.props.headContent.shareImg}
									/>
								}
							</div>
						</div>
						<RecipeFooter footerContent={recipeFooter} url={gravURL}/>
					</div>
					
				</div>
				<div className="printRecipe">
					<PrintRecipe recipe={recipe} method={this.props.method} ref={el => (this.componentRef = el)} /> 
				</div>
				</Fragment>
				}
			</Fragment>
		);
		
	}
}


const PrintRecipe = (props) => {
	let pageUrl = '';
	if(typeof window !== 'undefined'){
		pageUrl = window.location.href;
	}
	
	return (
		<section className="printPageContainer">
			<div className="text-right"><p><strong>New Image Keto | Recipes | {props.recipe.title}</strong><br/><small>{pageUrl}</small></p></div>
			<section className="pb-10 overflow-visible block">
				<h2 style={{fontSize:"30px", fontWeight: 700}}>{props.recipe.title}</h2>
				<PrintIngredientParts parts={props.recipe.ingredients_parts}/>
				<div className="mt-4">&emsp;</div>
				<h2 className="text-2xl text-secondary font-medium">Cooking Instructions</h2>
				{HtmlParser(props.method)}
			</section>
		</section>
	);
}