import React, { Component } from 'react';
import HtmlParser from 'react-html-parser'
export default class Ingredients extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	selectedItems: [],

	  };
	}
	componentDidMount(){
		this.getUserShoppingList();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.getUserShoppingList();
		}
	}
	getUserShoppingList(){
		if(this.props.user.Shopping_List){
			this.setState({selectedItems: this.props.user.Shopping_List})
		}
	}
	
	render() {
		let userIngredients = this.props.ingredients.ingredients.map((item, key) =>
		     <Ingredient value={item.value}  
		    	measurement={item.rawValue} 
		    	ingredient={item.ingredient} 
		    	key={key} 
		    	handleAdd={this.props.handleAdd}
		    	category={item.category}
		    	list={this.state.selectedItems}
		    	selectable={true}
		    	recipeName={this.props.recipeName}
		    	pageUrl={this.props.pageUrl}
		    	ingredientType={item.type}
		    />
		);
		let ingredients = this.props.ingredients.ingredients.map((item, key) =>
		    <Ingredient value={item.value}  
		    	measurement={item.rawValue} 
		    	ingredient={item.ingredient}  
		    	handleAdd={this.props.handleAdd}
		    	category={item.category}
		    	selectable={false}
		    	ingredientType={item.type}
		    />
		);

		const {uid, heading} = this.props;
		return (
			<div>
				{heading&&<h4 className="text-1xl text-secondary font-medium mt-4 mb-2">{this.props.heading}</h4>}
				{uid? 
					<div>
						<ul className="ingredientList">{userIngredients}</ul>
					</div>
				:
					<ul className="ingredientList">{ingredients}</ul>
				}
				
			</div>
		);
	}
}
//
//

export class Ingredient extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	onList: false,
	  	list: this.props.list,
	  	currentAmount: 0,
	  	preSelected: 'false',
	  };
	}
	checkList(){
		let onList = false;
		let list = this.props.list;
		if(this.props.list){
		for(let i = 0; list.length > i; i++){
			if(list[i].ingredient === this.props.ingredient){
				onList = true;
				let recipes = list[i].recipes;
				if(recipes && this.props.recipeName){
					for(let r = 0; recipes.length > r; r++){
						if(this.props.recipeName.title === recipes[r].name ){
							this.setState({
								preSelected: true,
								key: i,
								currentAmount: list[i].value,
							})
						}
						else{
							this.setState({
								currentAmount: list[i].value,
								onList: 'true',	
							})
						}
					}	
				}
				else {
					this.setState({
						preSelected: true,
						key: i,
						currentAmount: this.props.value,
					})
				}
			}
		}	
		}
		
		if(onList === false){
			this.setState({onList: 'false'})
		}
	}
	componentDidMount(){
		if(this.props.uid){
			this.setState({selectable: true})
		}
		this.checkList();
	}
	componentDidUpdate(nextProps){
		if(this.props !== nextProps){
			this.checkList();
		}
	}
	handleListItem(e){
		let recipes = [];
		let value = false;
		let measurement = false;
		if(this.props.value){
			value = this.props.value;
		}
		if(this.props.measurement){
			measurement = this.props.measurement;
		}
		let recipeDetails = {
			name: this.props.recipeName.title,
			url: this.props.pageUrl,
			measurement: measurement,
			cateory: this.props.category,
			value: value,
		}
		recipes.push(recipeDetails)
		this.props.handleAdd(
			e, 
			this.props.measurement, 
			this.props.value, 
			this.props.ingredient, 
			this.props.category,
			recipes,
		)
	}
	render(){
		let needSpace = 
		this.props.measurement === 'pinch' || 
		this.props.measurement === 'tbsp' || 
		this.props.measurement === 'tsp' || 
		this.props.measurement === 'scoop' || 
		this.props.measurement === 'handful' || 
		this.props.measurement === 'cup' ||
		typeof this.props.value === 'number';
		let noVal = this.props.value === undefined;
		let onList = this.state.onList === 'true';
		let blankMeasurement = this.props.measurement === 'value';
		if(this.props.selectable === true){
			return(
				<li>
					<button className="ingredientListItem"
						data-selected={this.state.preSelected}
						onClick={this.handleListItem.bind(this)} 
						data-key={this.state.key}
						data-recipe={this.props.recipeName}
						data-measurement={this.props.measurement}
						data-value={this.props.value}
						data-category={this.props.category}
						data-ingredient={this.props.ingredient}
					>
						
			    	<span>{HtmlParser(this.props.value)} {needSpace&& <>&ensp;</>}</span>
			    	{blankMeasurement? null:<span>{this.props.measurement} </span>}
					</button>
			    	
				<span>{noVal? null: <>&ensp;</>}{HtmlParser(this.props.ingredient)} {this.props.ingredientType}</span>
			    	{onList?<span className="onList">&emsp; already on list</span>:null}
			    </li>
			)	
		}
		
		else {
			return(
				<li className="ingredientListItem">	
			    	<span>{HtmlParser(this.props.value)}{needSpace&& <>&ensp;</>}</span>
			    	{blankMeasurement? null:<span>{this.props.measurement} </span>}
			    	<span>{blankMeasurement? null: <>&ensp;</>}{HtmlParser(this.props.ingredient)} {this.props.ingredientType}</span>
			    </li>
			)	
		}
	}
}